:root {
	--amplify-primary-color: #5664d2;
	--amplify-primary-shade: rgb(60, 70, 147);
	--amplify-primary-tint: #5664d2;
	--amplify-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	--amplify-red: #f50057;
}

amplify-authenticator {
	--border-radius: 16px;
	--box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 2px 0 rgba(63, 63, 68, 0.15);
}
